import React, { useState } from 'react'
import { Flex, Heading } from 'rebass'
import Button from '../../../sharedComponents/atoms/Button'
import { SmartPINDeliveryMethod } from '../../interfaces'
// import { useConfig } from '../../accountMgmt/state/accountConfigSetup'
import { useLocale } from '../../localization'
import { RadioButton } from '@soluto-private/mx-asurion-ui-react'
import { maskEmail } from '../../utility/masking'
import '../styles/TescoSignIn.css'

interface EnrollProps {
  onContinue: (x: SmartPINDeliveryMethod) => void
  mdn: string
  email: string
  hasEmail: boolean
  isLoading?: boolean
}
const SmartPinDelivery: React.FC<EnrollProps> = ({
  onContinue,
  mdn,
  email,
  hasEmail,
  isLoading,
}) => {
  const { strings } = useLocale()
  // const { ALLOW_EMAIL_SMART_PIN } = useConfig()
  const [chosenOption, setChosenOption] = useState<SmartPINDeliveryMethod>(
    SmartPINDeliveryMethod.Email
  )

  const handleContinue = () => {
    onContinue(chosenOption)
  }

  const handleOptionChange = (option: SmartPINDeliveryMethod) => () => {
    setChosenOption(option)
  }

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      sx={{ textAlign: 'center' }}
      py={4}
    >
      <Heading as="h1" fontSize={28} fontWeight={500}>
        {strings.smartPinDelivery.weWillSend}
      </Heading>
      <div className="tesco-signin-div">
        <div className="tesco-signin-radio-div">
          <RadioButton
            label={strings.smartPinDelivery.emailItToMe(maskEmail(email))}
            value={chosenOption}
            onChange={handleOptionChange(SmartPINDeliveryMethod.Email)}
            checked={chosenOption === SmartPINDeliveryMethod.Email}
          />
        </div>
        <div className="tesco-signin-radio-div">
          <RadioButton
            label={strings.smartPinDelivery.textItToMe(mdn)}
            value={chosenOption}
            onChange={handleOptionChange(SmartPINDeliveryMethod.SMS)}
            checked={chosenOption === SmartPINDeliveryMethod.SMS}
          />
        </div>
      </div>
      <div>
        <Button
          data-cy="continueButton"
          mt={5}
          height={60}
          width={300}
          fontSize={3}
          showArrow={true}
          onClick={handleContinue}
          isLoading={isLoading}
        >
          {strings.misc.continue}
        </Button>
      </div>
    </Flex>
  )
}

export default SmartPinDelivery
