import React, { useState } from 'react'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import Info from './Info'
import AccountModal from './AccountModal'
import EmailModalContent from './EmailModalContent'
import NameModalContent from './NameModalContent'
import PlanModalContent from './PlanModalContent'
import PaymentModalContent from './PaymentModalContent'
import EnrolledDeviceModalContent from './EnrolledDeviceModalContent'
import formatPhoneNumber from '../../sharedHelpers/formatPhoneNumber'
import {
  IBillingAddress,
  BillingDetails,
  IFindAgreementResponse,
  IInvoice,
  EventType,
  isCCDetails,
} from '../../interfaces'
import useWidth from '../../sharedHelpers/useWidth'
import moment from 'moment'
import BillingDateModalContent from './BillingDateModalContent'
import formatNameOnCard from '../../sharedHelpers/formatNameOnCard'
import getNextBillDate from '../../sharedHelpers/getNextBillDate'
import { useActions } from '../state/accountStateMgmt'
import { useConfig } from '../state/accountConfigSetup'
import CancelPlanModalContent from './CancelPlanModalContent'
import paymentMethodDisplay from '../../sharedHelpers/paymentMethodDisplay'
import chooseCountryFromPartner from '../../../sharedHelpers/getCountryFromPartner'
import { useLocale } from '../../localization'
import formatDateByCountry from '../../sharedHelpers/formatDateByCountry'
import { getCardImageSrc } from './CreditCardName'
import { BillingStatus, IBillingContact } from '../../interfaces/misc'
// import { DegradePolicyModal } from './DegradePolicyModal'
import { NOTE_TEXTS, NOTE_TYPES } from '../common/constants'
import { maskEmail } from '../../utility/masking'
import ImageWithFallback from './ImageWithFallback'
import NoDeviceImage from '../../sharedAssets/no-device.svg'

interface IOverviewProps {
  details?: IFindAgreementResponse
  mdn: string
  billingDetails?: BillingDetails
  billingAddress?: IBillingAddress
  billingHistory?: IInvoice[]
  billingStatus?: string
  billingContact?: IBillingContact
}
const Overview: React.FC<IOverviewProps> = ({
  details,
  mdn,
  billingDetails,
  billingAddress,
  billingHistory,
  billingStatus,
  billingContact,
}) => {
  const [currentModalComponent, setCurrentModalComponent] =
    useState<React.ReactNode | null>(null)
  const width = useWidth()
  const { viewPaymentHistory, trackEvent, createNote } = useActions()
  const {
    SHOW_CANCEL_PLAN_PANEL,
    SHOW_COMMUNICATIONS_PANEL,
    PARTNER,
    ALLOW_NAME_CHANGE,
    COMMS_URL,
  } = useConfig()
  const COUNTRY = chooseCountryFromPartner(PARTNER)
  const { strings } = useLocale()
  const [isDegradePolicyModalOpen, setIsDegradePolicyModalOpen] =
    useState(false)

  if (!details || !billingDetails || !billingAddress) {
    return null
  }
  const nextBillDate = getNextBillDate(billingDetails)
  const isTerminated = details.status === 'TRMNTD'
  const formattedStartDate = formatDateByCountry(
    moment(details.product.startDate, 'YYYY-MM-DD'),
    COUNTRY
  )
  const formattedTerminationDate = details.terminationDate
    ? formatDateByCountry(
        moment(details.terminationDate, 'YYYY-MM-DD'),
        COUNTRY
      )
    : 'unknown'

  const handlePlanViewClick = () => {
    trackEvent(EventType.ViewPlanDetailsLinkClicked, { flow: 'AcctMgmt' })
    setCurrentModalComponent(
      <PlanModalContent plan={details.product} startDate={formattedStartDate} />
    )
  }

  const handleEnrolledViewClick = () => {
    trackEvent(EventType.ViewEnrolledDeviceLinkClicked, { flow: 'AcctMgmt' })
    setCurrentModalComponent(<EnrolledDeviceModalContent details={details} />)
  }

  const handleClose = async () => {
    if (isDegradePolicyModalOpen) {
      setIsDegradePolicyModalOpen(false)
      await handleDegradePolicyModalCloseButton()
    }
    setCurrentModalComponent(null)
  }

  const handlePaymentMethodUpdateClick = () => {
    trackEvent(EventType.ChangePaymentMethodLinkClicked, { flow: 'AcctMgmt' })
    setCurrentModalComponent(
      <PaymentModalContent
        nameOnCard={formatNameOnCard(billingDetails, details)}
        billingDetails={billingDetails}
        address={billingAddress}
        onClose={handleClose}
        billingContact={billingContact}
      />
    )
  }

  const handleNextPaymentUpdateClick = () => {
    trackEvent(EventType.ChangePaymentDateLinkClicked, { flow: 'AcctMgmt' })
    setCurrentModalComponent(
      <BillingDateModalContent
        currentDay={billingDetails.billDate}
        onClose={handleClose}
      />
    )
  }

  const handlePaymentHistoryViewClick = () => {
    trackEvent(EventType.ViewPaymentHistoryLinkClicked, { flow: 'AcctMgmt' })
    viewPaymentHistory()
  }

  const handleNameUpdateClick = () => {
    trackEvent(EventType.ChangeNameLinkClicked, { flow: 'AcctMgmt' })
    setCurrentModalComponent(
      <NameModalContent
        currentName={[details.customer.firstName, details.customer.lastName]}
        onClose={handleClose}
      />
    )
  }

  const handleEmailUpdateClick = () => {
    trackEvent(EventType.ChangeEmailAddressLinkClicked, { flow: 'AcctMgmt' })
    setCurrentModalComponent(
      <EmailModalContent
        currentEmail={maskEmail(details.contact.email || '') || ''}
        onClose={handleClose}
      />
    )
  }

  const handleCancelPlanClick = () => {
    trackEvent(EventType.CancelAgreementLinkClicked, { flow: 'AcctMgmt' })
    setCurrentModalComponent(
      // We don't yet have the ability to get the cancellation date in advance
      <CancelPlanModalContent
        endingDate={'the cancellation date'}
        onClose={() => {
          handleClose()

          //Disableing degrade policy survey popup
          // showDegradePolicyModal()
        }}
        keepMyPlan={() => {
          handleClose()
        }}
      />
    )
  }

  const handleDegradePolicyModalCloseButton = async () => {
    return await createNote({
      noteText: NOTE_TEXTS.NO_RESPONSE_TO_LOWER_PREMIUM,
      noteType: NOTE_TYPES.AGREEMENT,
      createdBy: 'Web',
    })
  }

  // const closeDegradePolicyModalOnUseChoice = () => {
  //   setIsDegradePolicyModalOpen(false)
  //   handleClose()
  // }

  // const showDegradePolicyModal = () => {
  //   setIsDegradePolicyModalOpen(true)
  //   setCurrentModalComponent(
  //     <DegradePolicyModal
  //       closeOnUserChoice={closeDegradePolicyModalOnUseChoice}
  //     />
  //   )
  // }

  const handleCommunicationsClick = () => {
    trackEvent(EventType.ViewCommunicationsLinkClicked, { flow: 'AcctMgmt' })
    window.open(COMMS_URL, '_blank')
  }

  const paymentHistory: IInvoice[] = billingHistory
    ? billingHistory
        .filter((p: IInvoice) => {
          return p.status === 'PAID'
        })
        .reverse()
    : []

  const payment: string =
    paymentHistory.length > 0
      ? strings.overview.lastPayment(
          formatDateByCountry(
            moment(paymentHistory[0].actualProcessDate),
            COUNTRY
          )
        )
      : strings.overview.noPreviousPayments

  const allowNameChange = !isTerminated && ALLOW_NAME_CHANGE
  const allowBillDateUpdate =
    billingStatus &&
    billingStatus === BillingStatus.Current &&
    details.status !== 'TRMNTD'

  return (
    <>
      {window.scrollTo(0, 0)}

      <AccountModal
        isModalOpen={!!currentModalComponent}
        onModalClose={handleClose}
      >
        {currentModalComponent}
      </AccountModal>

      <Grid
        container={true}
        spacing={width === 'xs' ? 0 : 3}
        direction={width === 'xs' ? 'column' : 'row'}
      >
        {/* LEFT PANEL */}
        <Grid item={true} sm={6}>
          <Box bgcolor="white" p={3} borderRadius="3px" boxShadow={'1'}>
            <Typography component="h2" variant="h6">
              {strings.overview.planInformation}
            </Typography>

            <Box mt={3} mb={4}>
              <Info
                data-cy={'plan-details'}
                title={strings.overview.planDetails}
                updateText={isTerminated ? undefined : 'View'}
                onUpdateClick={handlePlanViewClick}
                tooltipText="View Plan Details"
              >
                <Box>
                  <Box>{details.product.retailName}</Box>

                  <Box fontSize={14}>
                    {strings.overview.enrolledDate}: {formattedStartDate}
                  </Box>

                  {isTerminated && (
                    <Box fontSize={14}>
                      {strings.overview.terminationDate}:{' '}
                      {formattedTerminationDate}
                    </Box>
                  )}
                </Box>
              </Info>
            </Box>

            <Divider />

            <Box mt={3} mb={4}>
              <Info
                data-cy={'enrolled-number'}
                title={strings.overview.enrolledPhoneNumber}
              >
                <Box>{formatPhoneNumber(mdn, COUNTRY)}</Box>
              </Info>
            </Box>
            <Divider />
            <Box mt={3} mb={4}>
              <Info
                data-cy={'enrolled-device'}
                title={strings.overview.enrolledDevice}
                updateText={isTerminated ? undefined : strings.misc.view}
                onUpdateClick={handleEnrolledViewClick}
                tooltipText="View Device Details"
              >
                <Box display="flex">
                  <ImageWithFallback
                    src={details?.asset?.imagePath || ''}
                    altSrc={NoDeviceImage}
                    alt="device_img"
                    imgStyle={{ width: 'fit-content', height: '60px' }}
                  />
                  <Box ml={2}>{details.asset.assetCatalog.name}</Box>
                </Box>
              </Info>
            </Box>

            <Divider />

            <Box mt={3} mb={4}>
              <Info
                data-cy={'payment-method'}
                title={strings.overview.paymentMethod}
                updateText={isTerminated ? undefined : strings.misc.change}
                onUpdateClick={handlePaymentMethodUpdateClick}
                tooltipText="Update Payment Method"
              >
                <Box display="flex" style={{ alignItems: 'center' }}>
                  {isCCDetails(billingDetails) && (
                    <img
                      src={getCardImageSrc(billingDetails.cardType)}
                      alt={strings.misc.creditCardIcon}
                      style={{ width: 25, height: 25, marginRight: 10 }}
                    />
                  )}{' '}
                  <span>
                    {paymentMethodDisplay({ billingDetails, strings })}
                  </span>
                </Box>
              </Info>
            </Box>

            <Divider />

            <Box mt={3} mb={4}>
              <Info
                data-cy={'next-payment'}
                title={strings.overview.yourNextPayment}
                updateText={
                  allowBillDateUpdate ? strings.misc.change : undefined
                }
                onUpdateClick={handleNextPaymentUpdateClick}
                tooltipText="Update Next Payment Date"
              >
                <Box id="billing-date">
                  {formatDateByCountry(nextBillDate, COUNTRY)}

                  <br />

                  <Box fontSize={14}>
                    {strings.overview.everyOfTheMonth(
                      moment(billingDetails.billDate.toString(), 'Do').format(
                        'Do'
                      )
                    )}
                  </Box>
                </Box>
              </Info>
            </Box>

            <Divider />

            <Box my={3}>
              <Info
                data-cy={'payment-history'}
                title={strings.overview.paymentHistory}
                updateText={strings.misc.view}
                onUpdateClick={handlePaymentHistoryViewClick}
                tooltipText="View Payment History"
              >
                {payment}
              </Info>
            </Box>
          </Box>

          {SHOW_CANCEL_PLAN_PANEL && !isTerminated && (
            <Box
              bgcolor="white"
              p={3}
              borderRadius="3px"
              boxShadow={'1'}
              mt={3}
            >
              <Info
                data-cy={'cancel-plan'}
                title={strings.overview.cancelPlan}
                updateText={strings.misc.cancel}
                onUpdateClick={handleCancelPlanClick}
                tooltipText="Cancel Plan"
              />
            </Box>
          )}
        </Grid>

        {/* RIGHT PANEL */}
        <Grid item={true} sm={6}>
          <Box bgcolor="white" p={3} borderRadius="3px" boxShadow={'1'}>
            <Typography component="h2" variant="h6">
              {strings.overview.contactInformation}
            </Typography>

            <Box mt={3} mb={4}>
              <Info
                data-cy={'name'}
                title={strings.misc.name}
                updateText={allowNameChange ? strings.misc.change : undefined}
                onUpdateClick={handleNameUpdateClick}
                tooltipText="Update Name"
              >
                {details.customer.firstName} {details.customer.lastName}
              </Info>
            </Box>

            <Divider />

            <Box my={3}>
              <Info
                data-cy={'email'}
                title={strings.misc.email}
                updateText={isTerminated ? undefined : strings.misc.change}
                onUpdateClick={handleEmailUpdateClick}
                tooltipText="Update Email"
              >
                {maskEmail(details.contact.email || '')}
              </Info>
            </Box>
          </Box>

          {SHOW_COMMUNICATIONS_PANEL && (
            <Box
              bgcolor="white"
              p={3}
              borderRadius="3px"
              boxShadow={'1'}
              mt={3}
            >
              <Info
                data-cy={'communications'}
                title={strings.overview.communications}
                updateText={strings.misc.view}
                onUpdateClick={handleCommunicationsClick}
                tooltipText="View Communication details"
              >
                {strings.overview.emailsAndOther}
              </Info>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default Overview
