import React, { useState } from 'react'
import { Country, HandleErrorType, IPlan, EventType } from '../interfaces'
import SignIn from './SignIn'
import { Box } from 'rebass'
import NoPhone from '../../sharedComponents/NoPhone'

interface SignInProps {
  agentSignIn: () => void
  country: Country
  disableCaptcha: boolean
  handleError: HandleErrorType
  isAgent: boolean
  isSignIn: boolean
  loading: boolean
  mdnMask: string
  mobileNumber: string
  onClose?: () => void
  reCaptchaKey: string
  reCaptchaToken: string
  selectedPlan: IPlan | null
  submit: (
    mdn: string,
    lastName: string,
    postalCode: string,
    reCaptcha: string
  ) => void
  showTextMessageDisclaimer: boolean
  trackEvent: (eventType: EventType, props?: { [key: string]: string }) => void
}
const SignInContainer: React.FC<SignInProps> = ({
  country,
  disableCaptcha,
  isSignIn,
  loading,
  mdnMask,
  onClose,
  reCaptchaKey,
  submit,
  handleError,
  showTextMessageDisclaimer,
  trackEvent,
}) => {
  const [showNoPhone, setShowNoPhone] = useState(false)

  function handleContinue({
    mdn,
    lastName,
    postalCode,
    reCaptchaToken,
  }: {
    mdn: string
    lastName: string
    postalCode: string
    reCaptchaToken: string
  }) {
    if (reCaptchaToken || disableCaptcha) {
      submit(mdn, lastName, postalCode, reCaptchaToken)
    }
  }

  const handleShowNoPhone = (bool: boolean) => () => {
    bool &&
      trackEvent('NoPhoneLinkClick' as any, {
        from: 'Sign In Page',
        to: 'No Phone Modal',
      })
    setShowNoPhone(bool)
  }

  const handleNoPhoneClose = () => {
    trackEvent('NoPhoneModalClosed' as any, {
      from: 'No Phone Modal',
      to: 'Marketing Page',
    })
    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      <Box sx={{ display: showNoPhone ? 'block' : 'none' }}>
        <NoPhone
          onBack={handleShowNoPhone(false)}
          onClose={handleNoPhoneClose}
        />
      </Box>

      <Box sx={{ display: showNoPhone ? 'none' : 'block' }}>
        <SignIn
          signInMode={isSignIn}
          onContinue={handleContinue}
          onNoPhone={handleShowNoPhone(true)}
          disableReCaptcha={disableCaptcha}
          reCaptchaKey={reCaptchaKey}
          mdnMask={mdnMask}
          isLoading={loading}
          country={country}
          handleError={handleError}
          showTextMessageDisclaimer={showTextMessageDisclaimer}
        />
      </Box>
    </>
  )
}

export default SignInContainer
