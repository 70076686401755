import React from 'react'
import { Box, Grid, Link, Typography } from '@material-ui/core'
import Info from './Info'
import {
  BillingDetails,
  BillingStatus,
  IBillingAddress,
  IBillingContact,
  IFindAgreementResponse,
  IInvoice,
} from '../../interfaces'
import AccountModal from './AccountModal'
import PaymentModalContent from './PaymentModalContent'
import moment from 'moment'
import useWidth from '../../sharedHelpers/useWidth'
import formatPrice from '../../sharedHelpers/formatPrice'
import PaymentStatus from './PaymentStatus'
import BillingDateModalContent from './BillingDateModalContent'
import MakePaymentModalContent from './MakePaymentModalContent'
import formatNameOnCard from '../../sharedHelpers/formatNameOnCard'
import getNextBillDate from '../../sharedHelpers/getNextBillDate'
import paymentMethodDisplay from '../../sharedHelpers/paymentMethodDisplay'
import { useLocale } from '../../localization'
import formatDateByCountry from '../../sharedHelpers/formatDateByCountry'
import chooseCountryFromPartner from '../../../sharedHelpers/getCountryFromPartner'
import { useConfig } from '../state/accountConfigSetup'
import formatPhoneNumber from '../../sharedHelpers/formatPhoneNumber'
import { useActions, useStore, useApi } from '../state/accountStateMgmt'
import { BillingModal } from '../state/accountActions'
import { EventType } from '../../interfaces/events'
import { AccountMgmtStrings } from '../../localization/en'

const getBillingStatusUpdateText = ({
  billingStatus,
  allowMakeupPayment,
  strings,
}: {
  billingStatus?: BillingStatus
  allowMakeupPayment: boolean
  strings: AccountMgmtStrings
}): string | undefined => {
  switch (billingStatus) {
    case BillingStatus.Current: {
      return
    }
    case BillingStatus.Pending: {
      return strings.misc.updatePaymentMethod
    }
    case BillingStatus.PastDue: {
      return allowMakeupPayment ? strings.misc.pay : undefined
    }
  }
}

interface IBillingProps {
  details: IFindAgreementResponse
  billingDetails?: BillingDetails
  billingAddress?: IBillingAddress
  billingHistory?: IInvoice[]
  billingStatus?: BillingStatus
  billingContact?: IBillingContact
}
const Billing: React.FC<IBillingProps> = ({
  details,
  billingDetails,
  billingHistory,
  billingAddress,
  billingStatus,
  billingContact,
}) => {
  const width = useWidth()
  const { PARTNER, CUST_SERV_PHONE_NO, ALLOW_MAKEUP_PAYMENT } = useConfig()
  const { currentBillingModal } = useStore()
  const { showBillingModal } = useActions()
  const { strings } = useLocale()
  const COUNTRY = chooseCountryFromPartner(PARTNER)
  const apiClient = useApi()
  if (!billingDetails || !billingHistory || !billingAddress) {
    return <Box p={3}>{strings.misc.loading}...</Box>
  }

  const nextBillDate = getNextBillDate(billingDetails)
  const isTerminated = details.status === 'TRMNTD'

  const handleClose = () => {
    showBillingModal()
  }

  const modalMap = (currentBillingModal: BillingModal): React.ReactElement => {
    switch (currentBillingModal) {
      case BillingModal.AdHocPayment: {
        return (
          <MakePaymentModalContent
            details={details}
            billingHistory={billingHistory}
            billingDetails={billingDetails}
            billingAddress={billingAddress}
            billingStatus={billingStatus}
            billingContact={billingContact}
            onClose={handleClose}
          />
        )
      }
      case BillingModal.NextPayment: {
        return (
          <BillingDateModalContent
            currentDay={billingDetails.billDate}
            onClose={handleClose}
          />
        )
      }
      case BillingModal.PaymentMethod: {
        return (
          <PaymentModalContent
            nameOnCard={formatNameOnCard(billingDetails, details)}
            billingDetails={billingDetails}
            address={billingAddress}
            onClose={handleClose}
            billingContact={billingContact}
          />
        )
      }
    }
  }

  const handleAdHocPayment = () => {
    showBillingModal(BillingModal.AdHocPayment)
  }

  const handleNextPaymentUpdate = () => {
    showBillingModal(BillingModal.NextPayment)
  }

  const handlePaymentUpdate = () => {
    showBillingModal(BillingModal.PaymentMethod)
  }

  const handleBillingStatusCTA = () => {
    if (billingStatus === BillingStatus.PastDue) {
      handleAdHocPayment()
    } else {
      handlePaymentUpdate()
    }
  }

  const paidBillingHistory = billingHistory
    .filter((p: IInvoice) => {
      return p.status === 'PAID'
    })
    .slice(-3)
    .reverse()

  const track = (
    eventType: EventType,
    props: { to: string; from: string; flow: string }
  ) => {
    return async () => {
      return await apiClient.trackEvent(eventType, props)
    }
  }

  const allowBillDateUpdate =
    billingStatus &&
    billingStatus === BillingStatus.Current &&
    details.status !== 'TRMNTD'
  return (
    <>
      {window.scrollTo(0, 0)}

      <AccountModal
        isModalOpen={currentBillingModal !== undefined}
        onModalClose={handleClose}
      >
        {currentBillingModal !== undefined && modalMap(currentBillingModal)}
      </AccountModal>
      <Box bgcolor="white" p={3} borderRadius="3px" boxShadow={'1'}>
        <Box mb={3}>
          <Typography component="h2" variant="h6">
            {strings.billing.billingInformation}
          </Typography>
        </Box>
        <Grid
          container={true}
          spacing={3}
          direction={width === 'xs' ? 'column' : 'row'}
        >
          <Grid item={true} sm={3}>
            <Info
              data-cy={'billing-status'}
              title={strings.billing.billingStatus}
              onUpdateClick={handleBillingStatusCTA}
              updateText={getBillingStatusUpdateText({
                billingStatus,
                allowMakeupPayment: ALLOW_MAKEUP_PAYMENT,
                strings,
              })}
              columnStyling={true}
            >
              <PaymentStatus
                billingStatus={
                  billingStatus ? billingStatus : BillingStatus.Pending
                }
              />
            </Info>
          </Grid>
          <Grid item={true} sm={3}>
            <Info
              data-cy={'next-payment'}
              title={strings.billing.yourNextPayment}
              updateText={allowBillDateUpdate ? strings.misc.change : undefined}
              onUpdateClick={handleNextPaymentUpdate}
              columnStyling={true}
              tooltipText="Update Next Payment Date"
            >
              <Box id="billing-date" mt={0}>
                {formatDateByCountry(nextBillDate, COUNTRY)}

                <br />

                <Box fontSize={14}>
                  Every{' '}
                  {moment(billingDetails.billDate.toString(), 'Do').format(
                    'Do'
                  )}{' '}
                  {strings.billing.ofTheMonth}
                </Box>
              </Box>
            </Info>
          </Grid>
          <Grid item={true} sm={3}>
            <Info
              data-cy={'recurring-payment'}
              title={strings.billing.recurringPaymentTotal}
            >
              {formatPrice(
                billingDetails.premiumAmount -
                  (billingDetails.discountAmount
                    ? billingDetails.discountAmount
                    : 0),
                COUNTRY
              )}
            </Info>
          </Grid>
          <Grid item={true} sm={3}>
            <Info
              data-cy={'payment-method'}
              title={strings.billing.paymentMethod}
              updateText={isTerminated ? undefined : strings.misc.change}
              onUpdateClick={handlePaymentUpdate}
              columnStyling={true}
              tooltipText="Update Payment Method"
            >
              {paymentMethodDisplay({ billingDetails, strings })}
            </Info>
          </Grid>
        </Grid>
      </Box>
      <Box bgcolor="white" p={3} mt={4} borderRadius="3px" boxShadow={'1'}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography component="h2" variant="h6">
              {strings.billing.paymentHistory}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* TABLE */}
      <Box boxShadow={'1'}>
        {/* HEADER */}
        <Box bgcolor="#EBF0FE" p={3}>
          <Grid container={true}>
            <Grid item={true} xs={5}>
              <Box fontWeight={500} component="h3" color="black" m={0}>
                {strings.billing.referenceId}
              </Box>
            </Grid>

            <Grid item={true} xs={3}>
              <Box fontWeight={500} component="h3" color="black" m={0}>
                {strings.billing.amount}
              </Box>
            </Grid>

            <Grid item={true} xs={4}>
              <Box fontWeight={500} component="h3" color="black" m={0}>
                {strings.billing.date}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/*  ROWS */}
        {paidBillingHistory.map((p: IInvoice, i: number) => (
          <Box
            data-cy={`invoice-${
              i === paidBillingHistory.length - 1 ? 'last' : i
            }`}
            bgcolor={i % 2 === 0 ? 'white' : '#f8f8f8'}
            p={3}
            color="black"
            key={i}
          >
            <Grid container={true}>
              <Grid item={true} xs={5}>
                {p.invoiceId}
              </Grid>

              <Grid item={true} xs={3}>
                {formatPrice(p.invoiceAmount, COUNTRY)}
              </Grid>

              <Grid item={true} xs={4}>
                {formatDateByCountry(
                  moment(p.actualProcessDate, 'YYYY-MM-DD'),
                  COUNTRY
                )}
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>

      <Box
        display="flex"
        alignItems="center"
        my={4}
        justifyContent="center"
        color="#000000"
        mx={3}
      >
        <Box
          border="2px solid #000000"
          width={20}
          height={20}
          component="span"
          display="flex"
          color="inherit"
          borderRadius="100%"
          alignItems="center"
          justifyContent="center"
          mr={1}
          flexShrink={0}
        >
          ?
        </Box>

        <Box color="inherit">
          {strings.billing.haveABillingQuestion}{' '}
          <Link
            href={`tel: ${CUST_SERV_PHONE_NO}`}
            onClick={track(EventType.CallCenterLinkClicked, {
              to: 'Customer Service Phone Link',
              from: 'Billing Details',
              flow: 'AcctMgmt',
            })}
          >
            {formatPhoneNumber(CUST_SERV_PHONE_NO, COUNTRY) || ''}
          </Link>
        </Box>
      </Box>
    </>
  )
}

export default Billing
