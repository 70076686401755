import React from 'react'
import { Box, makeStyles, Tab, Tabs, Typography } from '@material-ui/core'
import { Route, Switch } from 'react-router'
import Overview from './Overview'
import Preferences from './Preferences'
import useReactRouter from 'use-react-router'
import useWidth from '../../sharedHelpers/useWidth'
import Billing from './Billing'
import { useStore, useActions } from '../state/accountStateMgmt'
import { useConfig } from '../state/accountConfigSetup'
import { useLocale } from '../../localization'
import { Icon } from '@soluto-private/mx-asurion-ui-react'

const useStyles = makeStyles({
  tab: {
    textTransform: 'none',
  },
})

const AccountMain = () => {
  const { APP_MAX_WIDTH, ACCOUNT_PATH } = useConfig()
  const {
    billingAddress,
    billingDetails,
    billingHistory,
    billingStatus,
    details,
    mdn,
    billingContact,
  } = useStore()
  const classes = useStyles()
  const { location, history } = useReactRouter()
  const width = useWidth()
  const { strings } = useLocale()
  const { allAgreements } = useStore()
  const { setBanner } = useActions()

  const pathSegments = location.pathname.split('/')
  const currentTab = pathSegments[pathSegments.length - 1]

  if (!details || !mdn) {
    return null
  }

  const handleNavChange = (e: React.ChangeEvent<{}>, value: string) => {
    if (value === 'back') {
      setBanner()
      history.push(`${ACCOUNT_PATH}/device-selection`)
    } else {
      history.push(`${ACCOUNT_PATH}/${value}`)
    }
  }

  return (
    <Box
      display="flex"
      flex="1 1 auto"
      flexDirection="column"
      justifyContent="space-between"
      pt={4}
    >
      <Box
        width="100%"
        flex="0 0 auto"
        px={3}
        display="flex"
        justifyContent="center"
      >
        <Box width={APP_MAX_WIDTH} maxWidth="100%">
          <Typography variant="h4" component="h1">
            {strings.accountMain.yourAccount}
          </Typography>

          <Box mt={3}>
            <Tabs
              value={currentTab}
              onChange={handleNavChange}
              indicatorColor="primary"
            >
              {allAgreements && allAgreements.length > 1 ? (
                <Tab
                  icon={
                    <div>
                      <Icon
                        fill="#000000"
                        size="large"
                        src="arrow-left"
                        style={{ marginBottom: '2px' }}
                      />
                      <span style={{ marginLeft: '5px' }}>
                        Device Selection
                      </span>
                    </div>
                  }
                  value="back"
                  data-cy="back-tab"
                  className={classes.tab}
                />
              ) : null}
              <Tab
                label={strings.accountMain.overview}
                value="overview"
                className={classes.tab}
                data-cy="overview-tab"
              />
              <Tab
                label={strings.accountMain.billingAndPaymentHistory}
                value="billing"
                className={classes.tab}
                data-cy="billing-tab"
              />
            </Tabs>
          </Box>
        </Box>
      </Box>

      <Box
        width="100%"
        flex="1 1 1"
        bgcolor="#F8F8F8"
        flexGrow={1}
        py={3}
        px={width === 'xs' ? 0 : 3}
      >
        <Box maxWidth={APP_MAX_WIDTH} ml="auto" mr="auto">
          <Switch>
            <Route
              path={`${ACCOUNT_PATH}/overview`}
              render={() => (
                <Overview
                  details={details}
                  mdn={mdn}
                  billingDetails={billingDetails}
                  billingAddress={billingAddress}
                  billingHistory={billingHistory}
                  billingStatus={billingStatus}
                  billingContact={billingContact}
                />
              )}
            />
            <Route
              path={`${ACCOUNT_PATH}/billing`}
              render={() => (
                <Billing
                  details={details}
                  billingDetails={billingDetails}
                  billingAddress={billingAddress}
                  billingHistory={billingHistory}
                  billingStatus={billingStatus}
                  billingContact={billingContact}
                />
              )}
            />
            <Route
              path={`${ACCOUNT_PATH}/preferences`}
              render={() => <Preferences details={details} />}
            />
          </Switch>
        </Box>
      </Box>
    </Box>
  )
}
export default AccountMain
