/* eslint-disable no-useless-escape */

const TESCO_NON_PROD_SCRIPT_SRC = `https://js-cdn.dynatrace.com/jstag/165658ccba3/bf39993uwd/f67e12d4c1fcfafe_complete.js`
const TESCO_PROD_SCRIPT_SRC = `https://js-cdn.dynatrace.com/jstag/165658ccba3/bf39993uwd/4401f5826e8e6e77_complete.js`
export const getDynatraceHeadElement = (isProd: boolean, partner: string) => ({
  attributes: {
    id: 'dynatrace-script',
    crossorigin: 'anonymous',
    src: isProd ? TESCO_PROD_SCRIPT_SRC : TESCO_NON_PROD_SCRIPT_SRC,
  },
  type: 'script',
})
