import { EventType } from '../interfaces/events'
import { useEffect, useState } from 'react'
import ApiClient from '../ApiClient'
import useReactRouter from 'use-react-router'

export const parseMatchForFlow = (path: string) => {
  let flow

  if (path.includes('enroll')) {
    flow = 'Enroll'
  } else if (path.includes('account')) {
    flow = 'AcctMgmt'
  }

  return flow
}

export const pageNameMap: Record<string, string> = {
  '/enroll/sign-in': 'In Store Rep Sign In page',
  '/enroll/customer-verification': 'MDN Entry page',
  '/enroll/smart-pin': 'Enter Verification Code page',
  '/enroll/device-selection': 'Device Selection page',
  '/enroll/account-information': 'Contact Information page',
  '/enroll/payment-information': 'Payment Information page',
  '/enroll/review': 'Review page',
  '/enroll/text-reminder': 'Text Reminder page',
  '/enroll/onboarding': 'Onboarding page',
  '/enroll/success': 'Enrollment Success page',
  '/enroll/plan-selection': 'Enrollment Plan Selection page',
  '/enroll/onboarding/schedule': 'Protech Schedule Date/Time Page',
  '/enroll/onboarding/number': 'Protech MDN Selection Page',
  '/enroll/onboarding/confirm': 'Protech Confirm Appointment Page',
  '/enroll/onboarding/end': 'Protech Review Appointment Page',
  '/account/': 'Account Sign In page',
  '/account/sign-in': 'Account Sign In page',
  '/account/smart-pin-delivery': 'Account Send Verification Code page',
  '/account/smart-pin': 'Account Enter Verification Code page',
  '/account/device-selection': 'Account Device Selection page',
  '/account/overview': 'Account Overview page',
  '/account/billing': 'Account Billing page',
  '/overview/': 'Overview page',
  '/faqs/': 'FAQ page',
  '/contact/': 'Contact page',
  '/support/': 'Support page',
  '/terms-and-conditions/': 'Terms and conditions page',
  '/privacy-notice/': 'Privacy Notice page',
  '/landing': 'Landing Page',
  '/eligibility': 'MDN/Carrier PIN Entry Page',
  '/coverage': 'Review Coverage Details Page',
  '/legal': 'Legal Review Page',
  '/done': 'Enrollment Confirmation Page',
  '/error': 'Error Page',
  '/login': 'Login Page',
}

export const parseLocationForFriendlyPageName = (pathName: string) => {
  return pageNameMap[pathName] || pathName
}

export default (props: { apiClient: ApiClient }) => {
  const { history, location } = useReactRouter()
  const [lastLocation, setLastLocation] = useState(location.pathname)

  useEffect(() => {
    return history.listen((current: any) => {
      props.apiClient.trackEvent(EventType.PageChange, {
        from: parseLocationForFriendlyPageName(lastLocation),
        to: parseLocationForFriendlyPageName(current.pathname),
        flow: parseMatchForFlow(current.pathname),
      })
      setLastLocation(current.pathname)
    })
  }, [history, props.apiClient, lastLocation])

  return null
}
