export interface AgreementInfo {
  agreementId: string
  subscriptionNumber: string
  billingProfileId: string
  status: string
  imagePath: string
  deviceData: Array<{ key: string; value: string }>
}

export interface AgreementStatusTagType {
  text: string
  showToolTip: boolean
  toolTipString: string
  bgColor: string
  txtColor: string
}

export const AgreementStatusTags: {
  [key: string]: AgreementStatusTagType
} = {
  INIT: {
    text: 'Active',
    showToolTip: false,
    toolTipString: '',
    bgColor: '#00539f',
    txtColor: '#ffffff',
  },
  ACTV: {
    text: 'Active',
    showToolTip: false,
    toolTipString: '',
    bgColor: '#00539f',
    txtColor: '#ffffff',
  },
  SUSPND: {
    text: 'Suspended',
    showToolTip: true,
    toolTipString:
      'Your monthly premium payment is not upto date. Please contact us on 03450303290 to make a payment or your policy may end up being cancelled',
    bgColor: '#6e6767',
    txtColor: '#ffffff',
  },
  PNDNG: {
    text: 'Pending',
    showToolTip: true,
    toolTipString:
      'Your policy is not completely set up. Please update your payment information or contact us on 03450303290',
    bgColor: '#6e6767',
    txtColor: '#ffffff',
  },
  DENIED: {
    text: 'Pending',
    showToolTip: true,
    toolTipString:
      'Your policy is not completely set up. Please update your payment information or contact us on 03450303290',
    bgColor: '#6e6767',
    txtColor: '#ffffff',
  },
  TRMNTD: {
    text: 'Cancelled',
    showToolTip: false,
    toolTipString: '',
    bgColor: '#ee1c2e',
    txtColor: '#ffffff',
  },
  EXPIRED: {
    text: 'Expired',
    showToolTip: false,
    toolTipString: '',
    bgColor: '#ee1c2e',
    txtColor: '#ffffff',
  },
}

export const AgreementStatusOrder = [
  'ACTV',
  'INIT',
  'PNDNG',
  'SUSPND',
  'TRMNTD',
  'EXPIRED',
  'DENIED',
]
