import React from 'react'
import { Box, Flex, Heading, Image } from 'rebass'
import Button from './atoms/Button'
import Back from './atoms/Back'
import exclamAlert from '../generic/sharedAssets/exclamAlert.svg'
import { useLocale } from '../generic/localization'

interface NoPhoneProps {
  onBack: () => void
  onClose: () => void
}
const NoPhone: React.FC<NoPhoneProps> = ({ onBack, onClose }) => {
  const { strings } = useLocale()

  return (
    <Box bg="#FFE767" m={-3} p={3}>
      <Back tabIndex={2} color="black" onClick={onBack} />
      <Flex flexDirection="column" px={[0, 4]} alignItems="flex-start">
        <Image mt={4} src={exclamAlert} />
        <Heading
          data-cy="noPhoneHeading"
          mt={4}
          fontSize={28}
          fontWeight={500}
          sx={{ maxWidth: '100%' }}
        >
          {strings.noPhone.toContinue}
        </Heading>
        <Box as="p" mt={4} sx={{ lineHeight: '145%', maxWidth: '100%' }}>
          {strings.noPhone.weNeedToTextYou}
        </Box>
        <Box mt={4} sx={{ fontWeight: 500, maxWidth: '100%' }}>
          {strings.noPhone.youHave}
        </Box>

        <Button
          tabIndex={1}
          color="black"
          my={4}
          height={60}
          width={300}
          fontSize={3}
          onClick={onClose}
          variant="outline"
        >
          {strings.misc.close}
        </Button>
      </Flex>
    </Box>
  )
}

export default NoPhone
