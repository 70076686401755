import React, { useState } from 'react'
import { Modal } from '@soluto-private/aui-react-modal'

interface ContactUsModalProps {
  heading: string
  subheading: string
  content: string
  contactDetails: { key: string; value: string }[]
  close: () => void
}

const ContactUsModal = (props: ContactUsModalProps) => {
  const { heading, subheading, contactDetails, content, close } = props
  const [isOpen, setIsOpen] = useState(true)

  const closeModal = () => {
    setIsOpen(false)
    close()
  }
  return (
    <Modal
      ariaLabel={heading}
      isOpen={isOpen}
      onCloseModal={closeModal}
      onClickCapture={closeModal}
    >
      <div style={{ margin: '30px' }}>
        <div>
          <h2>{heading}</h2>
        </div>
        <hr />
        <div style={{ marginTop: '15px' }}>
          <h3 style={{ margin: '0px' }}>{subheading}</h3>

          {contactDetails.map((contact: { key: string; value: string }) => {
            return (
              <h4
                style={{ margin: '0px' }}
              >{`${contact.key}: ${contact.value}`}</h4>
            )
          })}
        </div>
        <div style={{ marginTop: '15px' }}>
          <span>{content}</span>
        </div>
      </div>
    </Modal>
  )
}

export default ContactUsModal
