import React, { useState } from 'react'
import { Heading } from 'rebass'
import { SimpleCard, Tag, Icon } from '@soluto-private/mx-asurion-ui-react'
import {
  AgreementInfo,
  AgreementStatusTagType,
  AgreementStatusTags,
} from '../interfaces/deviceSelection'
import './styles/DeviceSelection.css'
import './styles/DeviceTooltip.css'
import NoDeviceImage from '../sharedAssets/no-device.svg'
import ImageWithFallback from '../accountMgmt/components/ImageWithFallback'
import ContactUsModal from '../accountMgmt/components/ContactUsModal'
import { useLocale } from '../localization'

interface DeviceSelectionProps {
  agreementList: AgreementInfo[]
  heading: string
  subHeading: string
  onSelect: (agreementId: string) => void
}

const DeviceSelection = (props: DeviceSelectionProps) => {
  const { agreementList, onSelect, heading, subHeading } = props
  const [showContactUsModal, setShowContactUsModal] = useState(false)
  const { contactUsModalStrings } = useLocale().strings

  const handleDeviceSelection = (agreementInfo: AgreementInfo) => {
    onSelect(agreementInfo.agreementId)
  }

  const getAgreementTag = (status: string) => {
    let tagType: AgreementStatusTagType
    if (Object.prototype.hasOwnProperty.call(AgreementStatusTags, status)) {
      tagType = AgreementStatusTags[status as keyof typeof AgreementStatusTags]
    } else {
      tagType = {
        text: 'Unknown',
        showToolTip: false,
        toolTipString: '',
        bgColor: '',
        txtColor: '',
      }
    }
    return tagType
  }

  const deviceTag = (agreementInfo: AgreementInfo) => {
    const tagData = getAgreementTag(agreementInfo.status)
    if (tagData.showToolTip) {
      return (
        <div className="deviceTooltip" data-tooltip={tagData.toolTipString}>
          <Tag
            text={tagData.text}
            style={{
              backgroundColor: tagData.bgColor,
              color: tagData.txtColor,
              fontWeight: 600,
            }}
          />
        </div>
      )
    } else {
      return (
        <Tag
          text={tagData.text}
          style={{
            backgroundColor: tagData.bgColor,
            color: tagData.txtColor,
            fontWeight: 600,
          }}
        />
      )
    }
  }

  return (
    <div className="ds_parent_div">
      <div className="ds_heading">
        <Heading as="h1" fontSize={28} fontWeight={500}>
          {heading}
        </Heading>
      </div>

      <div className="ds_heading">
        <span className="ds_sub_heading">{subHeading}</span>
      </div>

      <div
        className={`${agreementList.length > 2 ? 'ds_card' : 'ds_card_center'}`}
      >
        {agreementList.map((agreementInfo: AgreementInfo) => (
          <div className="ds_simple_card" key={agreementInfo.agreementId}>
            <SimpleCard
              variant="flat"
              onClick={() => handleDeviceSelection(agreementInfo)}
              style={{ padding: 'unset' }}
            >
              <div className="ds_image">
                <ImageWithFallback
                  src={agreementInfo.imagePath}
                  altSrc={NoDeviceImage}
                  alt="main_image"
                  imgStyle={{ width: 'fit-content', maxHeight: '185px' }}
                  noImageStyle={{ width: '110px', maxHeight: '185px' }}
                />
              </div>
              <div className="ds_info">
                <div className="ds_status">{deviceTag(agreementInfo)}</div>
                {agreementInfo.deviceData.map((deviceData, index) => (
                  <div className="ds_data" key={index}>
                    <span className="ds_key">{deviceData.key}: </span>
                    <span className="ds_value">{deviceData.value}</span>
                  </div>
                ))}
              </div>
            </SimpleCard>
          </div>
        ))}
      </div>

      <div className="ds_heading">
        <span className="ds_sub_heading">Can't see my device</span>
        <Icon
          fill="#000000"
          size="medium"
          src="system-help"
          onClick={() => {
            setShowContactUsModal(true)
          }}
          style={{ marginLeft: '5px', cursor: 'pointer' }}
        />
      </div>

      {showContactUsModal && (
        <ContactUsModal
          heading={contactUsModalStrings.heading}
          subheading={contactUsModalStrings.subheading}
          contactDetails={contactUsModalStrings.contactDetails}
          content={contactUsModalStrings.content}
          close={() => setShowContactUsModal(false)}
        />
      )}
    </div>
  )
}

export default DeviceSelection
