import React, { useCallback } from 'react'
import {
  BannerMsgId,
  HandleErrorType,
  ISendSmartPinRequest,
  PortalErrId,
} from '../interfaces'
import { EventType } from '../interfaces/events'
import SmartPin from './SmartPin'
import { useStore } from '../accountMgmt/state/accountStateMgmt'

interface IMappedState {
  mobileNumber: string
  loading: boolean
  sendVia: string
  reCaptchaToken: string
  countryCallingCode: string
  bannerMsgId?: BannerMsgId | null
}

interface IMappedDispatch {
  submit: (pin: string) => void
  setLoading: (b: boolean, isScreenBlocked?: boolean) => void
  handleError: HandleErrorType
  removeBannerError: () => void
  verificationCodeSent: (isResend: boolean) => void
  trackEvent: (eventType: EventType, props?: object) => Promise<void>
  sendSmartPin: (req: ISendSmartPinRequest) => Promise<{ sent: boolean }>
}

const SmartPinContainer: React.FC<IMappedState & IMappedDispatch> = ({
  mobileNumber,
  removeBannerError,
  handleError,
  setLoading,
  verificationCodeSent,
  submit,
  trackEvent,
  sendVia,
  reCaptchaToken,
  countryCallingCode,
  bannerMsgId,
  loading,
  ...otherProps
}) => {
  const sendSmartPinAction = otherProps.sendSmartPin
  const { email, lastName, postalCode } = useStore()

  const sendSmartPin = useCallback(
    async ({ isResend }: { isResend: boolean }) => {
      removeBannerError()

      try {
        setLoading(true, true)

        if (isResend) {
          await trackEvent(EventType.ResendSmartPin, {})
        }

        const params: ISendSmartPinRequest = {
          recaptcha: reCaptchaToken,
          countryCallingCode,
          phoneNumber: mobileNumber,
          sendVia,
          email,
          lastName,
          postalCode,
        }

        const sentResponse = await sendSmartPinAction(params)

        if (!sentResponse || !sentResponse.sent) {
          handleError({ portalErrId: PortalErrId.SMART_PIN_2 })
        }

        verificationCodeSent(isResend)
      } catch (error) {
        handleError({ portalErrId: PortalErrId.SMART_PIN_2 })
      } finally {
        setLoading(false)
      }
    },
    [
      removeBannerError,
      handleError,
      verificationCodeSent,
      mobileNumber,
      setLoading,
      trackEvent,
      sendVia,
      reCaptchaToken,
      countryCallingCode,
      sendSmartPinAction,
      email,
      lastName,
      postalCode,
    ]
  )

  const handleContinue = useCallback(
    (smartPin: string) => {
      submit(smartPin)
    },
    [submit]
  )

  const resendSmartPin = () => {
    sendSmartPin({ isResend: true })
  }

  return (
    <SmartPin
      onContinue={handleContinue}
      onSendAgain={resendSmartPin}
      isLoading={loading}
      hasError={!!bannerMsgId}
    />
  )
}

export default SmartPinContainer
