import React, { useState, useRef } from 'react'
import {
  BillingGatewayService,
  PaymentUI,
  Environment,
  BillingGatewayRegion,
  Locale,
  PaymentSuccessResponse,
  PaymentUIReference,
} from '@backoffice/fast-payments-client-js-sdk/microui'
import { useActions, useStore } from '../state/accountStateMgmt'
import { BannerMsgType } from '../../interfaces/misc'
import { IUpdateEnrollmentRequest } from '../../interfaces'
import { MICRO_UI_ERROR_CODES } from '../common/constants'
import { AlertBanner } from '@soluto-private/mx-asurion-ui-react'
import { useLocale } from '../../localization'
import SubmitButton from '../../sharedComponents/SubmitButton'

interface MicroUIProps {
  billingProgramId: string
  securityToken: string
  enrollmentAddress: {
    addressLine1: string
    addressLine2?: string
    city: string
    state?: string
    countryCode: string
    postalCode: string
    addressUsage: string
  }
  onPaymentSuccess: (paymentData: IUpdateEnrollmentRequest) => void
  onFailure: () => void
}

const PaymentMicroUI: React.FC<MicroUIProps> = (props) => {
  const { setBanner } = useActions()
  const { strings } = useLocale()
  const { loading, details } = useStore()
  const { enrollmentAddress, securityToken, billingProgramId } = props
  const billingAddress = {
    address1: enrollmentAddress.addressLine1,
    address2: enrollmentAddress.addressLine2,
    city: enrollmentAddress.city,
    stateOrProvince: enrollmentAddress.state,
    postalCode: enrollmentAddress.postalCode,
  }
  const [microUIError, setMicroUIError] = useState('')
  const [isMicroUIAlertVisible, setMicroUIAlertVisible] = useState(false)
  const [disableSaveButton, setDisableSaveButton] = useState(false)
  const paymentUI = useRef(null as PaymentUIReference | null)

  const onSuccess = (response: PaymentSuccessResponse) => {
    setDisableSaveButton(false)
    const ccValidity = response?.additionalData?.cardExpirationDate ?? ''
    const ccExpMonth = ccValidity?.split('/')[0]
    const ccExpYear = ccValidity?.split('/')[1]
    const agreementId = details?.agreementId ?? ''
    const updateEnrollmentPayload: IUpdateEnrollmentRequest = {
      agreementId,
      updatePaymentMethod: true,
      securityToken: securityToken,
      firstName: response?.billingInformation?.contact?.firstName ?? '',
      lastName: response?.billingInformation?.contact?.lastName ?? '',
      billingAddress: {
        addressLine1: response?.billingInformation?.address?.address1 ?? '',
        addressLine2: response?.billingInformation?.address?.address2 ?? '',
        city: response?.billingInformation?.address?.city ?? '',
        postalCode: response?.billingInformation?.address?.postalCode ?? '',
        state: response?.billingInformation?.address?.stateOrProvince ?? '',
        countryCode: 'GB',
        addressUsage: 'BLNG',
      },
      paymentMethodDetails: {
        CardExpirationMonth: ccExpMonth,
        CardExpirationYear: ccExpYear,
        CardHolderFirstName:
          response?.billingInformation?.contact?.firstName ?? '',
        CardHolderLastName:
          response?.billingInformation?.contact?.lastName ?? '',
        CardNumber: response?.additionalData?.cardLastFourDigits ?? '',
        CardPostalCode: response?.billingInformation?.address?.postalCode ?? '',
        CardType: response?.additionalData?.cardBrand ?? '',
      },
    }
    props.onPaymentSuccess(updateEnrollmentPayload)
  }

  const onError = (failResponse: any) => {
    setDisableSaveButton(false)
    const keys = Object.keys(MICRO_UI_ERROR_CODES)
    let key
    let error
    if (failResponse?.bgCode) {
      key = keys.find((k) => k === failResponse.bgCode)
    } else {
      key = keys.find((k) => k === failResponse.reason)
    }
    if (key) {
      error = MICRO_UI_ERROR_CODES[key as keyof typeof MICRO_UI_ERROR_CODES]
    } else {
      error = {
        message: failResponse.reason,
        closeMicroUI: false,
        showAlert: true,
      }
    }
    //Note: custome aletrs for errors are disabled, in future if required we can enable it.
    error.showAlert = false
    //failResponse?.status === MICRO_UI_ERROR_TYPES.WARNING ? true : false
    setMicroUIError(error.message)
    setMicroUIAlertVisible(error.showAlert)
    if (error.closeMicroUI) {
      setBanner({
        type: BannerMsgType.Error,
        msg: error.message,
      })
      props.onFailure()
    }
  }

  return (
    <div>
      <div style={{ paddingBottom: '15px' }}>
        <AlertBanner
          alertBannerType="error"
          isOpen={isMicroUIAlertVisible}
          onCloseClick={() => {
            setMicroUIError('')
            setMicroUIAlertVisible(false)
          }}
        >
          {microUIError}
        </AlertBanner>
      </div>
      <PaymentUI
        ref={paymentUI}
        applicationSession={securityToken}
        locale={Locale.UK}
        billingGatewayService={
          new BillingGatewayService({
            environment:
              process.env.NODE_ENV === 'production'
                ? Environment.PROD
                : Environment.QA,
            region: BillingGatewayRegion.EU,
            applicationId: 'Tesco',
            billingProgramId,
          })
        }
        billingInformation={{
          address: billingAddress,
        }}
        onPaymentSuccess={(response) => {
          onSuccess(response)
        }}
        onPaymentFailure={(failResponse) => {
          onError(failResponse)
        }}
        hideContinueButton={true}
      />
      <SubmitButton
        disabled={disableSaveButton}
        text={strings.creditCardChangeForm.saveChanges}
        id={'payment-submit'}
        loading={loading}
        onClick={() => {
          setDisableSaveButton(true)
          setMicroUIAlertVisible(false)
          setMicroUIError('')
          paymentUI.current?.continue()
        }}
      />
    </div>
  )
}

export default PaymentMicroUI
