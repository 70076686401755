import { FIELD_ERROR_MESSAGES } from '../common/constants'
import { Country } from '../interfaces'
import validatePhoneNumber from '../sharedHelpers/validatePhoneNumber'
import { TextFieldStatus } from '@soluto-private/mx-asurion-ui-react'

export const mdnValidation = (
  mdn: string,
  country: Country
): {
  isMdnValid: boolean
  errorMessage: string
  fieldStatus: TextFieldStatus
} => {
  const regex = /^[0-9]+$/
  let isMdnValid = false
  let errorMessage = ''
  let fieldStatus: TextFieldStatus = 'default'
  if (mdn.length === 0) {
    errorMessage = FIELD_ERROR_MESSAGES.MOBILE_NUMBER.EMPTY_ERROR
    fieldStatus = 'error'
    isMdnValid = false
  } else if (!regex.test(mdn)) {
    errorMessage = FIELD_ERROR_MESSAGES.MOBILE_NUMBER.ALPHANUMERIC_ERROR
    fieldStatus = 'error'
    isMdnValid = false
  } else if (mdn.length < 11 || mdn.length > 11) {
    errorMessage = FIELD_ERROR_MESSAGES.MOBILE_NUMBER.LENGTH_ERROR
    fieldStatus = 'error'
    isMdnValid = false
  } else if (!validatePhoneNumber(mdn, country)) {
    errorMessage = FIELD_ERROR_MESSAGES.MOBILE_NUMBER.INVALID_ERROR
    fieldStatus = 'error'
    isMdnValid = false
  } else {
    isMdnValid = true
  }
  return {
    isMdnValid,
    errorMessage,
    fieldStatus,
  }
}

export const lastNameValidation = (
  lastName: string
): {
  isLastNameValid: boolean
  errorMessage: string
  fieldStatus: TextFieldStatus
} => {
  const regex = /^[A-Za-z'`%,.\s-]+$/
  let isLastNameValid = false
  let errorMessage = ''
  let fieldStatus: TextFieldStatus = 'default'
  if (lastName === '') {
    errorMessage = FIELD_ERROR_MESSAGES.LAST_NAME.EMPTY_ERROR
    fieldStatus = 'error'
    isLastNameValid = false
  } else if (!regex.test(lastName)) {
    errorMessage = FIELD_ERROR_MESSAGES.LAST_NAME.INVALID_ERROR
    fieldStatus = 'error'
    isLastNameValid = false
  } else if (lastName.length > 40) {
    errorMessage = FIELD_ERROR_MESSAGES.LAST_NAME.LENGTH_ERROR
    fieldStatus = 'error'
    isLastNameValid = false
  } else {
    isLastNameValid = true
  }
  return {
    isLastNameValid,
    errorMessage,
    fieldStatus,
  }
}

export const postalCodeValidation = (postalCode: string) => {
  const regex = /^[a-zA-Z]{1,2}(\d{1,2}|\d{1}[a-zA-Z])\s*\d\s*[a-zA-Z]{2}$/i
  let isPostalCodeValid = false
  let errorMessage = ''
  let fieldStatus: TextFieldStatus = 'default'
  if (postalCode === '') {
    errorMessage = FIELD_ERROR_MESSAGES.POSTAL_CODE.EMPTY_ERROR
    fieldStatus = 'error'
    isPostalCodeValid = false
  } else if (!regex.test(postalCode)) {
    errorMessage = FIELD_ERROR_MESSAGES.POSTAL_CODE.INVALID_ERROR
    fieldStatus = 'error'
    isPostalCodeValid = false
  } else if (postalCode.length > 10 || postalCode.length < 4) {
    errorMessage = FIELD_ERROR_MESSAGES.POSTAL_CODE.LENGTH_ERROR
    fieldStatus = 'error'
    isPostalCodeValid = false
  } else {
    isPostalCodeValid = true
  }
  return {
    isPostalCodeValid,
    errorMessage,
    fieldStatus,
  }
}
