export enum EventType {
  InitialPageLoad = 'InitialPageLoad',
  InitializationError = 'InitializationError',
  InfoChanged = 'InfoChanged',
  ChangeInfoLinkClick = 'ChangeInfoLinkClick',
  ChangeDeviceLinkClick = 'ChangeDeviceLinkClick',
  ChatLinkClicked = 'ChatLinkClick',
  FrontendError = 'FrontendException',
  ResendSmartPin = 'ResendSmartPin',
  PageChange = 'PageChange',
  LinkClicked = 'LinkClicked',
  ManualAddressEnrty = 'ManualAddressEntry',
  ClaimLinkClicked = 'ClaimLinkClick',
  AuthenticatedClaimLinkClick = 'AuthenticatedClaimLinkClicked',
  CallCenterLinkClicked = 'CallCenterLinkClicked',
  ContinueButtonClicked = 'ContinueButtonClicked',
  BackButtonClicked = 'BackButtonClicked',
  AgreeToTermsChecked = 'AgreeToTermsChecked',
  LanguageChanged = 'LanguageChanged',
  ContinueToCoverageReviewPageClicked = 'ContinueToCoverageReviewPageClicked',
  StartClaimButtonClick = 'StartClaimButtonClick',
  CloseTAndCModal = 'CloseTAndCModal',
  TAndCDownloadLinkClick = 'TAndCDownloadLinkClick',

  // ACCT MGMT Summary page links
  MakeAdHocPaymentLinkClicked = 'MakeAdHocPaymentLinkClicked',
  ViewPlanDetailsLinkClicked = 'ViewPlanDetailsLinkClicked',
  ViewEnrolledDeviceLinkClicked = 'ViewEnrolledDeviceLinkClicked',
  ViewPaymentHistoryLinkClicked = 'ViewPaymentHistoryLinkClicked',
  ChangePaymentDateLinkClicked = 'ChangePaymentDateLinkClicked',
  ChangePaymentMethodLinkClicked = 'ChangePaymentMethodLinkClicked',
  ChangeEmailAddressLinkClicked = 'ChangeEmailAddressLinkClicked',
  ChangeNameLinkClicked = 'ChangeNameLinkClicked',
  CancelAgreementLinkClicked = 'CancelAgreementLinkClicked',
  ViewCommunicationsLinkClicked = 'ViewCommunicationsLinkClicked',
  FAQClicked = 'FAQClicked',
  WebsiteTAndCCheckboxChecked = 'WebsiteTAndCCheckboxChecked',
  WebsiteTAndCClicked = 'WebsiteTAndCClicked',
  WebsiteTAndCClickedByCheckbox = 'WebsiteTAndCClickedByCheckbox',
  WebsiteTAndCClickedDetailsPage = 'WebsiteTAndCClickedDetailsPage',
  // Static links
  CookieBannerLinkClick = 'CookieBannerLinkClicked',
  FooterContactUsLinkClicked = 'FooterContactUsLinkClicked',

  FooterMyAccountLinkClicked = 'FooterMyAccountLinkClicked',
  HeaderMyAccountLinkClicked = 'HeaderMyAccountLinkClicked',
  LandingPageManageAccountLinkClicked = 'LandingPageManageAccountLinkClicked',
  LandingPagePrivacyLinkClicked = 'LandingPagePrivacyLinkClicked',
  LandingPageContactLinkClicked = 'LandingPageContactLinkClicked',
  LandingPageTermsLinkClicked = 'LandingPageTermsLinkClicked',
  InformationBannerBrochureLinkClicked = 'InformationBannerBrochureLinkClicked',
  SignOutLinkClicked = 'SignOutLinkClicked',
  SMSReminderLinkClicked = 'SMSReminderLinkClicked',

  // Tesco
  LandingPageGetStartedClaimButtonClick = 'LandingPageGetStartedClaimButtonClick',
  UploadClaimDocumentsButtonClicked = 'UploadClaimDocumentsButtonClicked',
  // HideCookieBanner = 'HideCookieBanner',
  BrowserFingerprint = 'BrowserFingerprint',
  FooterTAndCLinkClicked = 'FooterTAndCLinkClicked',
  FooterTermsOfUseClicked = 'FooterTermsOfUseClicked',
  FooterPrivacyLinkClicked = 'FooterPrivacyLinkClicked',
  FooterTrademarkLinkClicked = 'FooterTrademarkLinkClicked',
  ShowDetailsClick = 'ShowDetailsClick',
  HideDetailsClick = 'HideDetailsClick',
  DoNotSellMyPersonalInformationLinkClicked = 'DoNotSellMyPersonalInformationLinkClicked',
}
