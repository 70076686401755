import SubmitButton from '../../sharedComponents/SubmitButton'
import { Box, Divider, makeStyles, Theme, Typography } from '@material-ui/core'
import React, { ChangeEvent, useState } from 'react'
import { Error } from '@material-ui/icons'
import { useConfig } from '../state/accountConfigSetup'
import { useActions, useStore } from '../state/accountStateMgmt'
import { TerminationReason } from '../../interfaces'
import { useLocale } from '../../localization'
import { RadioButton } from '@soluto-private/mx-asurion-ui-react'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

interface IProps {
  endingDate: string
  onClose: () => void
  keepMyPlan: () => void
}
const CancelPlanModalContent: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { CUST_SERV_PHONE_NO, SHOW_CANCELLATION_REASONS } = useConfig()
  const { cancelPlan } = useActions()
  const { loading, details } = useStore()
  const { strings } = useLocale()
  const [cancelReason, setCancelReason] = useState<TerminationReason>(
    TerminationReason.REGRETTERM
  )
  const [showReasonContent, setShowReasonContent] = useState(false)

  const handleFirstCancelClick = () => {
    SHOW_CANCELLATION_REASONS
      ? setShowReasonContent(true)
      : handleFinalCancelClick()
  }

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    const reason = e.target.value as unknown as TerminationReason
    setCancelReason(reason)
  }

  const handleFinalCancelClick = () => {
    cancelPlan({
      terminationReason: cancelReason,
      agreementId: details?.agreementId || '',
    }).finally(props.onClose)
  }

  return !showReasonContent ? (
    // FIRST PAGE
    <>
      <Box mb={3}>
        <Typography data-cy="modal-title" variant="h6">
          {strings.cancelPlanModalContent.areYouSure}
        </Typography>
      </Box>

      <Box display="flex">
        <Error style={{ color: '#B00020' }} />
        <Box ml={2} mb={3} color="textSecondary">
          {strings.cancelPlanModalContent.ifYouCancel(props.endingDate)}
        </Box>
      </Box>

      <Divider />

      <Box mt={2} mb={3}>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 'bold', fontSize: 14, marginLeft: '44px' }}
        >
          {strings.cancelPlanModalContent.questions(CUST_SERV_PHONE_NO)}
        </Typography>
      </Box>

      <SubmitButton
        id={'cancel'}
        text={strings.cancelPlanModalContent.yesCancel}
        onClick={handleFirstCancelClick}
        loading={loading}
      />

      <Box
        className={classes.link}
        onClick={props.keepMyPlan}
        textAlign="center"
        fontWeight={500}
        mt={4}
        mb={3}
      >
        {strings.cancelPlanModalContent.noCancel}
      </Box>
    </>
  ) : (
    // SECOND PAGE
    <>
      <Box mb={3}>
        <Typography data-cy="modal-title" variant="h6">
          {strings.cancelPlanModalContent.pleaseShare}
        </Typography>
      </Box>

      <div style={{ padding: '30px' }}>
        {/* Iterating over the actual TerminationReason enum proved to be difficult. */}
        {Object.keys(strings.cancelPlanModalContent.cancelPlanReason)
          // Remove "Fraudulent policy" and "I am receiving poor service from Asurion"
          .filter(
            (key) =>
              key !== TerminationReason.PF &&
              key !== TerminationReason.CSTMDSTSFN
          )
          .map((que, key) => {
            const keyAsEnum = que as TerminationReason
            return (
              <div key={key}>
                {/* <Label key={key} mb={3}>
                <Radio
                  name="color"
                  id={keyAsEnum}
                  value={keyAsEnum}
                  onChange={handleRadioChange}
                />
                {
                  strings.cancelPlanModalContent.cancelPlanReason[
                    TerminationReason[keyAsEnum]
                  ]
                }
              </Label> */}

                <RadioButton
                  label={
                    strings.cancelPlanModalContent.cancelPlanReason[
                      TerminationReason[keyAsEnum]
                    ]
                  }
                  value={keyAsEnum}
                  onChange={handleRadioChange}
                  checked={cancelReason === keyAsEnum}
                />
              </div>
            )
          })}
      </div>

      <SubmitButton
        id={'cancel'}
        text={strings.cancelPlanModalContent.cancelNow}
        onClick={handleFinalCancelClick}
        loading={loading}
      />

      <Box
        className={classes.link}
        onClick={props.keepMyPlan}
        textAlign="center"
        fontWeight={500}
        mt={4}
        mb={3}
      >
        {strings.cancelPlanModalContent.nevermind}
      </Box>
    </>
  )
}

export default CancelPlanModalContent
