import { IStore } from '../../interfaces/account'
import { getActions } from './accountActions'
import initStateManagement from '../../initStateMgmt'
import {
  IApiClientConstructorParams,
  SmartPINDeliveryMethod,
} from '../../interfaces'
import ApiClient from '../../ApiClient'

export const initAccountStore = (): IStore => {
  return {
    loading: false,
    isScreenBlocked: false,
    hasEmail: true,
    smartPINDeliveryMethod: SmartPINDeliveryMethod.SMS,
    signedIn: !!sessionStorage.getItem('token'),
    smartPinErrorCounter: 0,
    reCaptchaToken: '',
    subscriptionNumber: '',
    lastName: '',
    postalCode: '',
    lasNameAndPostalCodeMatch: false,
    billingProfileId: '',
    errorOccuredWhileFetchingAgreements: false,
    smartPinError: false,
  }
}

export const initAccountApiClient = (params: IApiClientConstructorParams) => {
  return new ApiClient(params)
}

const { connect, StoreProvider, useActions, useApi, useStore } =
  initStateManagement({
    getActions,
  })

export { connect, useStore, useActions, useApi, StoreProvider }
