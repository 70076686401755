import initLocale from '../initLocale'
import { Locale } from '../interfaces'
import en from './en'
import enGb from './enGb'

const { useLocale, LocaleProvider } = initLocale({
  [Locale.English]: en,
  [Locale.EnglishGB]: enGb,
})

export { useLocale, LocaleProvider }
