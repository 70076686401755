import React from 'react'
import {
  HandleErrorType,
  ISendSmartPinRequest,
  PortalErrId,
  SmartPINDeliveryMethod,
} from '../../interfaces'
import { useStore } from '../state/accountStateMgmt'
import SmartPinDelivery from './SmartPinDelivery'

const SmartPinDeliveryContainer = ({
  mdn,
  email,
  hasEmail,
  methodSelected,
  reCaptchaToken,
  handleError,
  countryCallingCode,
  setLoading,
  verificationCodeSent,
  lastName,
  postalCode,
}: {
  mdn: string
  email: string
  hasEmail: boolean
  reCaptchaToken: string
  lastName: string
  postalCode: string
  handleError: HandleErrorType
  countryCallingCode: string
  setLoading: (b: boolean) => void
  methodSelected: (
    t: SmartPINDeliveryMethod,
    r: ISendSmartPinRequest
  ) => Promise<{ sent: boolean }>
  verificationCodeSent: (isResend: boolean) => void
}) => {
  const { loading } = useStore()

  const handleContinue = async (method: SmartPINDeliveryMethod) => {
    setLoading(true)

    const smartPinSent: { sent: boolean } = await methodSelected(method, {
      sendVia: method,
      phoneNumber: mdn,
      recaptcha: reCaptchaToken,
      countryCallingCode,
      email,
      lastName,
      postalCode,
    })

    if (!smartPinSent || !smartPinSent.sent) {
      handleError({ portalErrId: PortalErrId.SMART_PIN_2 })
    } else {
      verificationCodeSent(false)
    }

    setLoading(false)
  }

  return (
    <SmartPinDelivery
      onContinue={handleContinue}
      mdn={mdn}
      email={email}
      hasEmail={hasEmail}
      isLoading={loading}
    />
  )
}

export default SmartPinDeliveryContainer
