import React, { useEffect, useState, ChangeEvent } from 'react'
import { TextField, TextFieldStatus } from '@soluto-private/mx-asurion-ui-react'
import { Country } from '../interfaces'
import { FIELD_LABELS } from '../common/constants'
import {
  mdnValidation,
  lastNameValidation,
  postalCodeValidation,
} from '../utility/fieldValidation'
import './styles/SignIn.css'

interface TescoSignInProps {
  country: Country
  isValid: (flag: boolean) => void
  setData: (data: { mdn: string; lastName: string; postalCode: string }) => void
}

const TescoSignIn = ({ country, isValid, setData }: TescoSignInProps) => {
  const [mdn, setMdn] = useState('')
  const [mdnHelperText, setMdnHelperText] = useState('')
  const [mdnFieldStatus, setMdnFieldStatus] =
    useState<TextFieldStatus>('default')
  const [lastNameHelperText, setLastNameHelperText] = useState('')
  const [lastNameFieldStatus, setLastNameFieldStatus] =
    useState<TextFieldStatus>('default')
  const [postalCodeHelperText, setPostalCodeHelperText] = useState('')
  const [postalCodeFieldStatus, setPostalCodeFieldStatus] =
    useState<TextFieldStatus>('default')
  const [isMdnValid, setIsMdnValid] = useState(false)
  const [isLastNameValid, setIsLastNameValid] = useState(false)
  const [isPostalCodeValid, setIsPostalCodeValid] = useState(false)
  const [lastName, setLastName] = useState('')
  const [postalCode, setPostalCode] = useState('')

  useEffect(() => {
    if (isMdnValid && isLastNameValid && isPostalCodeValid) {
      isValid(true)
      setData({
        mdn,
        lastName: lastName.trim(),
        postalCode: postalCode.trim(),
      })
    }
  }, [
    mdn,
    isMdnValid,
    lastName,
    isLastNameValid,
    postalCode,
    isPostalCodeValid,
    isValid,
    setData,
  ])

  const handleMdnChange = (e: ChangeEvent<HTMLInputElement>) => {
    validateMdn(e.target.value)
    setMdn(e.target.value)
  }

  const validateMdn = (mdn: string) => {
    const response: {
      isMdnValid: boolean
      errorMessage: string
      fieldStatus: TextFieldStatus
    } = mdnValidation(mdn, country)
    setMdnHelperText(response.errorMessage)
    setMdnFieldStatus(response.fieldStatus)
    setIsMdnValid(response.isMdnValid)
    isValid(response.isMdnValid)
  }

  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const lName = e.target.value
    validateLastName(lName)
    setLastName(lName)
  }

  const validateLastName = (lastName: string) => {
    const response: {
      isLastNameValid: boolean
      errorMessage: string
      fieldStatus: TextFieldStatus
    } = lastNameValidation(lastName)
    setLastNameHelperText(response.errorMessage)
    setLastNameFieldStatus(response.fieldStatus)
    setIsLastNameValid(response.isLastNameValid)
    isValid(response.isLastNameValid)
  }

  const handlePostalCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const poctal_code = e.target.value
    validatePostalCode(poctal_code)
    setPostalCode(poctal_code)
  }

  const validatePostalCode = (postalCode: string) => {
    const response: {
      isPostalCodeValid: boolean
      errorMessage: string
      fieldStatus: TextFieldStatus
    } = postalCodeValidation(postalCode)
    setPostalCodeHelperText(response.errorMessage)
    setPostalCodeFieldStatus(response.fieldStatus)
    setIsPostalCodeValid(response.isPostalCodeValid)
    isValid(response.isPostalCodeValid)
  }

  return (
    <div className="sign-in-div">
      <div className="sign-in-text-field">
        <TextField
          label={FIELD_LABELS.MOBILE_NUMBER}
          value={mdn}
          fieldStatus={mdnFieldStatus}
          helperText={mdnHelperText}
          onChange={handleMdnChange}
        />
      </div>

      <div className="sign-in-text-field">
        <TextField
          label={FIELD_LABELS.LAST_NAME}
          value={lastName}
          fieldStatus={lastNameFieldStatus}
          helperText={lastNameHelperText}
          onChange={handleLastNameChange}
        />
      </div>

      <div className="sign-in-text-field">
        <TextField
          label={FIELD_LABELS.POSTAL_CODE}
          value={postalCode}
          fieldStatus={postalCodeFieldStatus}
          helperText={postalCodeHelperText}
          onChange={handlePostalCodeChange}
        />
      </div>
    </div>
  )
}

export default TescoSignIn
