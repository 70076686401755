import React from 'react'
import { Box, makeStyles, Theme } from '@material-ui/core'
import { Icon, Tooltip } from '@soluto-private/mx-asurion-ui-react'
import { ICON_MAPPING } from '../common/constants'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

interface IInfoProps {
  title: string
  updateText?: string
  onUpdateClick?: () => void
  columnStyling?: boolean
  children?: React.ReactNode | undefined
  tooltipText?: string
}
const Info: React.FC<IInfoProps> = ({
  title,
  updateText,
  onUpdateClick,
  columnStyling,
  children,
  tooltipText,
}) => {
  const classes = useStyles()

  const columnVersion = (
    <>
      <Box fontWeight={500} component="h3" color="black" m={0}>
        {title}
      </Box>

      {children && (
        <Box data-cy={title.toLowerCase() + '-children'} mt={1}>
          {children}
        </Box>
      )}

      {updateText && onUpdateClick && (
        <Box
          data-cy={title.toLowerCase()}
          className={classes.link}
          onClick={onUpdateClick}
          mt={1}
        >
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'left',
            }}
          >
            {Object.keys(ICON_MAPPING).includes(updateText) ? (
              <Tooltip
                label={tooltipText ? tooltipText : updateText}
                maxWidth={300}
              >
                <Icon
                  fill="rgba(0, 0, 0, 1)"
                  size="medium"
                  src={ICON_MAPPING[updateText as keyof typeof ICON_MAPPING]}
                  title=""
                />
              </Tooltip>
            ) : (
              updateText
            )}
          </div>
        </Box>
      )}
    </>
  )

  const rowVersion = (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box fontWeight={500} component="h3" color="black" m={0}>
          {title}
        </Box>

        {updateText && onUpdateClick && (
          <Box
            data-cy={title.toLowerCase()}
            className={classes.link}
            onClick={onUpdateClick}
          >
            {Object.keys(ICON_MAPPING).includes(updateText) ? (
              <Tooltip
                label={tooltipText ? tooltipText : updateText}
                maxWidth={300}
              >
                <Icon
                  fill="rgba(0, 0, 0, 1)"
                  size="medium"
                  src={ICON_MAPPING[updateText as keyof typeof ICON_MAPPING]}
                  title=""
                />
              </Tooltip>
            ) : (
              updateText
            )}
          </Box>
        )}
      </Box>

      {children && (
        <Box data-cy={title.toLowerCase() + '-children'} mt={1}>
          {children}
        </Box>
      )}
    </>
  )

  return columnStyling ? columnVersion : rowVersion
}

export default Info
