import React, { FormEvent, useEffect, useState } from 'react'
import { Box, Flex, Heading } from 'rebass'
import PinEntry from '../../sharedComponents/atoms/PinEntry'
import Back from '../../sharedComponents/atoms/Back'
import Button from '../../sharedComponents/atoms/Button'
import { useLocale } from '../localization'
import { useStore } from '../accountMgmt/state/accountStateMgmt'

interface VerifyProps {
  onContinue: (pin: string) => void
  onSendAgain: () => void
  onBack?: () => void
  isLoading?: boolean
  hasError?: boolean
  errorMsg?: string
}
const SmartPin: React.FC<VerifyProps> = ({
  onContinue,
  onSendAgain,
  onBack,
  isLoading,
  hasError,
  errorMsg,
}) => {
  const { strings } = useLocale()
  const [pin, setPin] = useState('')
  const isPinValid = pin.length === 4 || pin.length === 6
  const { smartPinError } = useStore()

  useEffect(() => {
    if (hasError || smartPinError) {
      setPin('')
    }
  }, [hasError, smartPinError])

  const handlePinChange = (pin: string) => {
    setPin(pin)
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (isPinValid) {
      onContinue(pin)
    }
  }

  return (
    <>
      {onBack && <Back onClick={onBack} />}

      {errorMsg && (
        <Flex
          justifyContent="center"
          alignItems="center"
          mt={3}
          mx={-3}
          height={50}
          bg="error"
          color="white"
          sx={{ textAlign: 'center' }}
        >
          {errorMsg}
        </Flex>
      )}

      <Flex
        as="form"
        onSubmit={handleSubmit}
        flexDirection="column"
        alignItems="center"
        sx={{ textAlign: 'center' }}
        py={4}
      >
        <Heading as="h1" fontSize={28} fontWeight={500}>
          {strings.sharedComponents.smartPin.enterCode}
        </Heading>
        <Box as="p" mt={4} sx={{ lineHeight: '145%' }}>
          {strings.sharedComponents.smartPin.itMayTake}
        </Box>
        <Button
          variant="unstyled"
          type="button"
          data-cy="resendSmartPin"
          mt={1}
          color="primary"
          sx={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={onSendAgain}
        >
          {strings.sharedComponents.smartPin.sendAgain}
        </Button>

        <PinEntry
          id="smartPin"
          mt={4}
          onPinChange={handlePinChange}
          pinValue={pin}
          isError={hasError}
        />

        <Button
          data-cy="smartPinContinue"
          mt={4}
          height={60}
          width={300}
          fontSize={3}
          showArrow={true}
          isLoading={isLoading}
          disabled={!isPinValid}
        >
          {strings.sharedComponents.misc.continue}
        </Button>
      </Flex>
    </>
  )
}

export default SmartPin
