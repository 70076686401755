import React, { useEffect, useState } from 'react'
import { PageLoader } from '@soluto-private/mx-asurion-ui-react'

interface ImageWithFallbackProps {
  src: string
  altSrc: string
  alt: string
  imgStyle: React.CSSProperties
  noImageStyle?: React.CSSProperties
}

const ImageWithFallback = (props: ImageWithFallbackProps) => {
  const { src, alt, imgStyle, altSrc, noImageStyle } = props
  const [imgSrc, setImgSrc] = useState(src)
  const [imgAlt, setImgAlt] = useState(alt)
  const [showLoader, setShowLoader] = useState(true)
  const [imageStyling, setImageStyling] = useState<React.CSSProperties>({
    display: 'none',
  })
  const [isAlternateImgLoaded, setIsAlternateImgLoaded] = useState(false)

  useEffect(() => {
    if (!showLoader && !isAlternateImgLoaded) {
      setImageStyling(imgStyle)
    }
  }, [showLoader, setImageStyling, imgStyle, isAlternateImgLoaded])

  const handleImageLoadError = () => {
    setImgSrc(altSrc)
    setImageStyling(noImageStyle || imgStyle)
    setImgAlt('fallback_image')
    setIsAlternateImgLoaded(true)
    setShowLoader(false)
  }

  const handleImageLoad = () => {
    setShowLoader(false)
  }

  return (
    <>
      {showLoader ? <PageLoader /> : null}
      <img
        style={imageStyling}
        src={imgSrc}
        alt={imgAlt}
        onLoad={handleImageLoad}
        onError={handleImageLoadError}
      />
    </>
  )
}

export default ImageWithFallback
