export const FIELD_ERROR_MESSAGES = {
  MOBILE_NUMBER: {
    ALPHANUMERIC_ERROR: 'Mobile number should contain only numbers.',
    EMPTY_ERROR: 'Please enter the mobile number of the device.',
    INVALID_ERROR: 'Please enter valid mobile number.',
    LENGTH_ERROR: 'Mobile number should be 11 digits long.',
  },
  LAST_NAME: {
    INVALID_ERROR: `Please enter valid last name.`,
    EMPTY_ERROR: 'Last name cannot be empty.',
    LENGTH_ERROR: 'Last name should not exceed 40 characters.',
  },
  POSTAL_CODE: {
    INVALID_ERROR: 'Please enter valid postal code',
    EMPTY_ERROR: 'Postal code cannot be empty.',
    LENGTH_ERROR: 'Postal code must be between 4 and 10 characters long.',
  },
}

export const FIELD_LABELS = {
  MOBILE_NUMBER: 'Mobile number',
  LAST_NAME: 'Last name',
  POSTAL_CODE: 'Postal code',
}

export const AGREEMENT_STATUS = [
  'EXPIRED',
  'TRMNTD',
  'PNDNG',
  'INVALID',
  'INIT',
  'DENIED',
  'SUSPND',
  'ACTV',
]
