import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { IProduct } from '../../interfaces'
import { useLocale } from '../../localization'

enum Entitlement {
  Insurance,
  ProTech,
}

const entitlementsByRetailSku: Record<string, Entitlement[]> = {
  PDPE: [Entitlement.Insurance, Entitlement.ProTech],
  PDPO: [Entitlement.ProTech],
  PDPF: [Entitlement.Insurance],
}

const doesSkuHaveThisEntitlement = (
  retailSku: string,
  entitlement: Entitlement
): boolean => {
  const entitlements = entitlementsByRetailSku[retailSku]
  return entitlements && entitlements.includes(entitlement)
}

interface IProps {
  plan: IProduct
  startDate: string
}
const PlanModalContent: React.FC<IProps> = ({ plan, startDate }) => {
  const { strings } = useLocale()

  return (
    <>
      <Box>
        <Typography data-cy="modal-title" variant="h6">
          {strings.planModalContent.planDetails}
        </Typography>

        <Box fontWeight={500} component="h4" color="black" mt={4} mb={0}>
          {plan.retailName}
        </Box>
        <Box fontSize={14} mt={0} mb={1}>
          {strings.overview.enrolledDate} {startDate}
        </Box>
        <Typography variant="body2">
          {strings.planModalContent.comprehensive}
        </Typography>

        {doesSkuHaveThisEntitlement(plan.retailSKU, Entitlement.Insurance) && (
          <>
            <Box fontWeight={500} component="h4" color="black" mt={4} mb={1}>
              {strings.planModalContent.insurance}
            </Box>
            <Typography variant="body2">
              - {strings.planModalContent.asSoonAsNext}
            </Typography>
            <Typography variant="body2">
              - {strings.planModalContent.screenRepair}
            </Typography>
            <Typography variant="body2">
              - {strings.planModalContent.upToTwo}
            </Typography>
          </>
        )}
      </Box>
    </>
  )
}

export default PlanModalContent
