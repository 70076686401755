export const maskEmail = (email: string): string => {
  let maskedemail = ''
  if (email) {
    const [localPart, domain] = email.split('@')
    const maskedLocalPart =
      localPart.charAt(0) + '*'.repeat(localPart.length - 1)
    const pos = domain.lastIndexOf('.')
    const part1 = domain.substring(0, pos)
    const part2 = domain.substring(pos)
    const maskedDomain = part1.charAt(0) + '*'.repeat(part1.length - 1)
    maskedemail = `${maskedLocalPart}@${maskedDomain}${part2}`
  }
  return maskedemail
}

export const maskIMEI = (input: string): string => {
  if (input.length <= 5) {
    return input
  }
  const maskedPart = '*'.repeat(input.length - 5)
  const visiblePart = input.slice(-5)
  return maskedPart + visiblePart
}
