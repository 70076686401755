import React, { FormEvent, useState } from 'react'
import { Box, Flex, Heading } from 'rebass'
import Button from '../../sharedComponents/atoms/Button'
import ReCAPTCHA from 'react-google-recaptcha'
import cleanNumberString from '../../sharedHelpers/cleanNumberString'
import validatePhoneNumber from '../sharedHelpers/validatePhoneNumber'
import { Country, HandleErrorType, PortalErrId } from '../interfaces'
import useWindowSize from '../../sharedHelpers/useWindowSize'
import { useLocale } from '../localization'
import TescoSignIn from './TescoSignIn'

interface SignInProps {
  onContinue: (x: {
    mdn: string
    lastName: string
    postalCode: string
    reCaptchaToken: string
  }) => void
  onNoPhone: () => void
  signInMode?: boolean
  reCaptchaKey: string
  disableReCaptcha?: boolean
  mdnMask?: string
  isLoading?: boolean
  country: Country
  handleError: HandleErrorType
  showTextMessageDisclaimer: boolean
}
const SignIn: React.FC<SignInProps> = ({
  onContinue,
  onNoPhone,
  signInMode,
  reCaptchaKey,
  disableReCaptcha,
  mdnMask,
  isLoading,
  country,
  handleError,
  showTextMessageDisclaimer,
}) => {
  const [mdn, setMdn] = useState('')
  const [lastName, setLastName] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [reCaptchaToken, setReCaptchaToken] = useState(
    disableReCaptcha ? 'fakeReCaptchaToken' : ''
  )
  const { strings } = useLocale()
  const { width } = useWindowSize()
  const [isTescoSignInFormValid, setIsTescoSignInFormValid] = useState(false)

  const handleTescoSignInFormChange = (flag: boolean) => {
    setIsTescoSignInFormValid(flag)
  }

  const setTescoSignInData = (data: {
    mdn: string
    lastName: string
    postalCode: string
  }) => {
    setMdn(data.mdn)
    setLastName(data.lastName)
    setPostalCode(data.postalCode)
  }

  // Note, this function is only called on successful completion of the reCAPTCHA.
  const handleReCaptchaChange = (token: string | null) => {
    if (token) {
      setReCaptchaToken(token)
    }
  }

  const handleReCaptchaError = () => {
    // Show a terminal error page
    handleError({ portalErrId: PortalErrId.RECAPTCHA_2 })
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    onContinue({
      mdn: cleanNumberString(mdn),
      lastName,
      postalCode,
      reCaptchaToken,
    })
  }

  const isFormValid =
    !!mdn &&
    !!lastName &&
    !!postalCode &&
    validatePhoneNumber(mdn, country) &&
    !!reCaptchaToken &&
    isTescoSignInFormValid

  return (
    <Flex
      as="form"
      flexDirection="column"
      alignItems="center"
      sx={{ textAlign: 'center' }}
      py={4}
      onSubmit={handleSubmit}
    >
      <Heading as="h1" fontSize={28} fontWeight={500}>
        {signInMode
          ? strings.sharedComponents.signIn.signIn
          : strings.sharedComponents.signIn.getYouEnrolled}
      </Heading>
      <Box as="p" mt={4} sx={{ lineHeight: '145%' }}>
        {strings.sharedComponents.signIn.makeSure[0]} {width > 500 && <br />}
        {strings.sharedComponents.signIn.makeSure[1]}
      </Box>

      {/* MDN component */}
      {/* <Input
        aria-label="Phone number"
        isUnderlined={true}
        mt={4}
        width={470}
        sx={{ maxWidth: '100%' }}
        value={mdn}
        onChange={handleMdnChange}
        mask={mdnMask || '(999) 999-9999'}
        type="tel"
        data-cy="mdnInput"
      /> */}
      <TescoSignIn
        country={country}
        isValid={handleTescoSignInFormChange}
        setData={setTescoSignInData}
      />

      {showTextMessageDisclaimer && (
        <Box mt={2} sx={{ maxWidth: 480 }}>
          {strings.sharedComponents.signIn.disclaimer}
        </Box>
      )}

      {/* Recaptcha Component */}
      {!disableReCaptcha ? (
        <Box
          mt={4}
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column' as 'column',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '100%',
            width: 304,
          }}
        >
          <Box
            sx={{
              '@media screen and (max-width: 400px)': {
                transform: 'scale(.84)',
                transformOrigin: 'center center',
              },
            }}
          >
            <ReCAPTCHA
              sitekey={reCaptchaKey}
              onChange={handleReCaptchaChange}
              onErrored={handleReCaptchaError}
            />
          </Box>
        </Box>
      ) : (
        <Flex
          mt={4}
          justifyContent="center"
          alignItems="center"
          width={305}
          height={77}
          bg="#F9F9F9"
        >
          reCAPTCHA goes here
        </Flex>
      )}

      {/* Continue Button component */}
      <Button
        data-cy="signInContinue"
        mt={4}
        height={60}
        width={300}
        fontSize={3}
        showArrow={true}
        isLoading={isLoading}
        disabled={!isFormValid}
      >
        {strings.sharedComponents.signIn.continue}
      </Button>

      {!signInMode && (
        <Button
          variant="unstyled"
          type="button"
          data-cy="dontHavePhone"
          mt={4}
          color="primary"
          fontSize={3}
          sx={{ cursor: 'pointer' }}
          onClick={onNoPhone}
        >
          {strings.sharedComponents.signIn.dontHavePhone}
        </Button>
      )}
    </Flex>
  )
}

export default SignIn
