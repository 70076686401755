import React, { useState, useEffect } from 'react'
import DeviceSelection from './DeviceSelection'
import { useStore, useActions } from '../accountMgmt/state/accountStateMgmt'
import {
  AgreementInfo,
  AgreementStatusOrder,
} from '../interfaces/deviceSelection'
import { IFindAgreementResponse } from '../interfaces/index'
import { useLocale } from '../localization'
import { maskIMEI } from '../utility/masking'
import moment from 'moment'

const DeviceSelectionContainer = () => {
  const [agreementList, setAgreementList] = useState<AgreementInfo[]>([])
  const { allAgreements } = useStore()
  const { setAgreement } = useActions()
  const { strings } = useLocale()

  useEffect(() => {
    if (allAgreements && allAgreements.length > 0) {
      const agreements: AgreementInfo[] = allAgreements.map(
        (agreement: IFindAgreementResponse) => {
          return {
            agreementId: agreement.agreementId,
            subscriptionNumber: agreement.subscriptionNumber,
            billingProfileId: agreement.billingProfileId,
            status: agreement.status,
            agreementPurcheseDate: agreement.product.startDate,
            agreementType: agreement.product.retailName,
            imagePath: agreement.asset.imagePath || '',
            deviceData: prepareDeviceData(agreement) as Array<{
              key: string
              value: string
            }>,
          }
        }
      )

      setAgreementList(sortAgreementsByStatus(agreements))
    }
  }, [allAgreements])

  const sortAgreementsByStatus = (agreementList: AgreementInfo[]) => {
    const statusOrder = AgreementStatusOrder
    return agreementList.sort((a, b) => {
      return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)
    })
  }

  const deviceSelected = (agreementId: string) => {
    setAgreement({ agreementId })
  }

  const prepareDeviceData = (agreement: IFindAgreementResponse) => {
    const fields = [
      { key: 'Model', value: agreement.asset?.assetCatalog?.name },
      { key: 'Colour', value: agreement.asset?.color },
      {
        key: 'IMEI',
        value: agreement.asset?.imei
          ? maskIMEI(agreement.asset.imei)
          : undefined,
      },
      { key: 'Cover', value: agreement.product?.retailName },
      {
        key: 'Purchase Date',
        value: agreement.product?.startDate
          ? moment(agreement.product.startDate).format('DD/MM/YYYY')
          : undefined,
      },
    ]

    return fields.filter((field) => field.value !== undefined)
  }

  return (
    <div>
      <DeviceSelection
        agreementList={agreementList}
        onSelect={deviceSelected}
        heading={
          strings.sharedComponents.tescoEnhancement.deviceSelectionPageHeading
        }
        subHeading={
          strings.sharedComponents.tescoEnhancement
            .deviceSelectionPageSubHeading
        }
      />
    </div>
  )
}

export default DeviceSelectionContainer
