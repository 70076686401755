export default {
  API_BASE_URL: process.env.REACT_APP_API_URL || '',
  TESCO_API_BASE_URL: process.env.REACT_APP_TESCO_API_URL || '',
  BG_ENDPOINT: process.env.REACT_APP_BG_ENDPOINT || '',
  CLAIM_DOCUMENTS_URL: process.env.REACT_APP_CLAIM_DOCUMENTS_URL || '',
  DISABLE_CAPTCHA: process.env.NODE_ENV !== 'production',
  DISABLE_REFRESH: process.env.REACT_APP_DISABLE_REFRESH === 'true',
  MIXPANEL_ENABLED: process.env.REACT_APP_MIXPANEL_ENABLED === 'true',
  RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY || '',
  IS_DOWN_FOR_MAINTENANCE:
    process.env.REACT_APP_IS_DOWN_FOR_MAINTENANCE === 'true',
  SPEEDCLAIMS_URL: process.env.REACT_APP_SPEEDCLAIMS_URL || '',
  COMMS_URL: process.env.REACT_APP_COMMS_URL || '',
  ENABLE_DYNAMIC_CONFIG: process.env.REACT_APP_ENABLE_DYNAMIC_CONFIG === 'true',
  TESCO_MICRO_UI_BPID: process.env.REACT_APP_TESCO_MICRO_UI_BPID || '',
  TESCO_MICRO_UI_FEATURE_FLAG:
    process.env.REACT_APP_MICRO_UI_FEATURE_FLAG === 'true',
}
